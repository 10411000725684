import * as React from "react";
import "./Footer.scss";
import { FunctionComponent } from "react";
import { Link } from "@reach/router";

type Props = {};

const Footer: FunctionComponent<Props> = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="content has-text-centered">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <strong>Get-Outt</strong>
              </div>
              <div className="level-item">Copyright 2019</div>
              <div className="level-item">
                <a href="/privacy" target="_blank">
                  Privacy Statement
                </a>
              </div>
            </div>
            <div className="level-right">
              <p>
                <strong>Website Design: </strong>
                <a href="http://www.curaelabs.eu" target="_blank">
                  curaelabs.eu
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
