import * as React from "react";
import { FunctionComponent } from "react";
var showdown = require("showdown"),
  converter = new showdown.Converter();

type Props = {
  children: string;
};

const Markdown: FunctionComponent<Props> = props => {
  return <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(props.children) }} />;
};

export default Markdown;
