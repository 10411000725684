import * as React from "react";
import "./Checkout.scss";
import { Component } from "react";
import { connect } from "react-redux";
import { toggleCart } from "./Reducers/GeneralReducer";
import { CartItem } from "./Data";
import { removeFromCart, changeAmount, resetCart } from "./Reducers/CartReducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { finishCheckout } from "./Api";
import { format } from "./currency";

type State = {
  step: number;
  email: string;
  firstname: string;
  lastname: string;
  address: string;
  number: string;
  postal: string;
  city: string;
  mobile: string;
  btw: string;
  remarks: string;
  choice: string;
};

type StateFromProps = {
  show: boolean;
  items: CartItem[];
};

type DispatchFromProps = {
  toggleCart: () => void;
  resetCart: () => void;
  removeFromCart: (id: string) => void;
  changeAmount: (id: string, amount: number) => void;
};

class Checkout extends Component<StateFromProps & DispatchFromProps, State> {
  state: State = {
    step: 0,
    email: "",
    firstname: "",
    lastname: "",
    address: "",
    number: "",
    postal: "",
    city: "",
    remarks: "",
    mobile: "",
    btw: "",
    choice: "Kopen - Afrekenen",
  };

  price(items: CartItem[]){
    var total = 0;

    for(var i = 0; i<items.length; i++){
      total += items[i].product.price * items[i].amount;
    }

    return total;
  }

  renderStep0() {
    const { items, toggleCart, removeFromCart } = this.props;
    const { step } = this.state;
    return (
      <>
        <div className="top">
          <h2 className="title is-3 has-text-centered">Mijn Winkelwagen</h2>
        </div>
        <div className="content">
          {items.length !== 0 && (
            <div className="cart-header">
              <span>Aantal</span>
              <span>Prijs</span>
            </div>
          )}
          {items.length === 0 && (
            <div>
              <p>Er werden nog geen items toegevoegd aan de winkelwagen.</p>
            </div>
          )}
          {items.map(item => (
            <div className="cart-row">
              <img src={item.product.imageUrls[0]} alt={item.product.name} />
              <span className="name">{item.product.name}</span>
              <input
                type="number"
                className="input"
                value={item.amount}
                onChange={e => {
                  changeAmount(item.product.id, parseInt(e.target.value));
                }}
              />
              <span className="price">{format(item.product.price, item.product.tva)}{" "}</span>
              <button
                className="delete is-medium"
                onClick={() => {
                  removeFromCart(item.product.id);
                }}
              />
            </div>
          ))}
        </div>
        <div className="bottom">
          {items.length !== 0 && (
            <button
              className="button is-primary"
              onClick={() =>
                this.setState({
                  step: step + 1,
                })
              }
            >
              Volgende
            </button>
          )}
          <button className="button is-light" onClick={() => toggleCart()}>
            Verder Winkelen
          </button>
        </div>
      </>
    );
  }

  renderStep1() {
    const { step, email, firstname, lastname, address, number, postal, city, mobile, btw, remarks, choice } = this.state;
    const { items, resetCart } = this.props;
    return (
      <>
        <div className="top">
          <h2 className="title is-3 has-text-centered">Mijn Gegevens</h2>
        </div>
        <div className="content">
          <form onSubmit={()=>{
            this.setState({
              step: step + 1,
            });
            resetCart();
            finishCheckout(email, firstname, lastname, items, btw, remarks, choice, address + " " + number + " " + postal + " " + city, mobile);
          }}>
            <div className="field-row">
              <div className="field">
                <label className="label">Voornaam</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={firstname}
                    onChange={e => this.setState({ firstname: e.target.value })}
                    required
                    autoFocus
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Achternaam</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={lastname}
                    onChange={e => this.setState({ lastname: e.target.value })}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Mijn Emailadres</label>
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Ongeldig email-adres opgegeven."
                  placeholder="Bvb. naam@email.be"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  required
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon="envelope" />
                </span>
              </div>
            </div>
            <div className="field-row">
              <div className="field">
                <label className="label">Adres</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={address}
                    onChange={e => this.setState({ address: e.target.value })}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nummer</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={number}
                    onChange={e => this.setState({ number: e.target.value })}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="field-row">
              <div className="field">
                <label className="label">Postcode</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={postal}
                    onChange={e => this.setState({ postal: e.target.value })}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Stad</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder=""
                    value={city}
                    onChange={e => this.setState({ city: e.target.value })}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">GSM (Optioneel)</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder=""
                  value={mobile}
                  onChange={e => this.setState({ mobile: e.target.value })}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">BTW Nummer (Optioneel)</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder=""
                  value={btw}
                  onChange={e => this.setState({ btw: e.target.value })}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Opmerking</label>
              <div className="control">
                <textarea
                  className="textarea"
                  value={remarks}
                  onChange={e => this.setState({ remarks: e.target.value })}
                  placeholder=""
                />
              </div>
            </div>

            <div className="field">
              { (this.price(items) > 1000) && <label className="label">Offerte of direct afrekenen?</label> }
              <div className="control">
                {(this.price(items) > 1000) && <label className="radio">
                  <input type="radio" name="choice" value="Kopen - Offerte" checked={choice === "Kopen - Offerte"} onChange={e=>this.setState({
                    choice: e.currentTarget.value,
                  })} />
                  Offerte
                </label> }
                <label className="radio">
                  <input type="radio" name="choice" value="Kopen - Afrekenen" checked={choice === "Kopen - Afrekenen"} onChange={e=>this.setState({
                    choice: e.currentTarget.value,
                  })} />
                  Direct Kopen
                </label>
              </div>
            </div>
            <div className="bottom">
              <button
                className="button is-primary"
              >
                Verzenden
              </button>
              <button
                className="button is-light"
                onClick={() =>
                  this.setState({
                    step: step - 1,
                  })
                }
              >
                Vorige
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  renderStep2() {
    const { toggleCart } = this.props;
    return (
      <>
        <div className="top">
          <h2 className="title is-3 has-text-centered">Bedankt voor uw aanvraag!</h2>
        </div>
        <div className="content">
          <p>
            Uw aanvraag werd goed ontvangen.
            <br />
            Wij nemen spoedig met u contact op.
          </p>
        </div>
        <div className="bottom">
          <button
            className="button is-light"
            onClick={() => {
              toggleCart();
              this.setState({
                step: 0,
              });
            }}
          >
            Sluiten
          </button>
        </div>
      </>
    );
  }

  render() {
    const { show, toggleCart } = this.props;
    const { step } = this.state;
    return (
      <div className="checkout">
        <div className={show ? "modal is-active" : "modal"}>
          <div className="modal-background" />
          <div className="modal-content">
            {step === 0 && this.renderStep0()}
            {step === 1 && this.renderStep1()}
            {step === 2 && this.renderStep2()}
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => toggleCart()} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  show: state.general.showCart,
  items: state.cart.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleCart: () => dispatch(toggleCart()),
  resetCart: () => dispatch(resetCart()),
  removeFromCart: (id: string) => dispatch(removeFromCart(id)),
  changeAmount: (id: string, amount: number) => dispatch(changeAmount(id, amount)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
