import * as React from "react";
import "./App.scss";
import { FunctionComponent } from "react";
import { Router } from "@reach/router";

import { Provider } from "react-redux";
import { applyMiddleware, createStore, compose } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import getoutt from "./Reducers";

import Home from "./Home";
import Products from "./Products";
import Rent from "./Rent";
import Exercises from "./Exercises";
import News from "./News";
import Header from "./Header";
import Footer from "./Footer";
import Team from "./about/Team";
import Mission from "./about/Mission";
import Contact from "./about/Contact";

import { Data } from "./Data";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faInfo, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Checkout from "./Checkout";

library.add(faEnvelope);
library.add(faInfo);
library.add(faShoppingCart);

const store = createStore(
  getoutt,
  composeWithDevTools(
    //applyMiddleware(thunk, logger)
    // other store enhancers if any
  )
);

type Props = {
  data: Data;
};

const App: FunctionComponent<Props> = ({ data }) => {
  return (
    <Provider store={store}>
      <Header />
      <div className="page-content">
        <Router>
          <Home path="/" data={data} />
          <Products path="/producten/:productId" data={data} />
          <Products path="/producten" data={data} />
          <Rent path="/huur/:productId" data={data} />
          <Exercises path="/oefeningen/:selectedExerciseId" data={data} />
          <Exercises path="/oefeningen" data={data} />
          <News path="/nieuws/:articleId" data={data} />
          <News path="/nieuws" data={data} />
          <Team path="/over-ons/team" data={data} />
          <Mission path="/over-ons/missie" data={data} />
          <Contact path="/contact" data={data} />
        </Router>
        <Checkout />
      </div>
      <Footer />
    </Provider>
  );
};

export default App;
