import * as React from "react";
import "./Home.scss";
import { Component, FunctionComponent } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import { Data, Exercise, Product, Client, Article } from "./Data";
import { format } from "./currency";

//import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from "react-dates";

const ProductTile: FunctionComponent<Product & RouteComponentProps> = ({ id, name, price, imageUrls, tva }) => {
  const [imageUrl] = imageUrls;

  return (
    <div className="column product">
      <div className="card with-shadow has-hover">
        <div className="card-image has-text-centered">
          <Link to={`/producten/${id}`} className="img-has-overlay">
            <figure className="image is-4by3">
              <img src={imageUrl} alt={name} />
            </figure>
          </Link>
        </div>
        <div className="card-footer has-text-centered">
          <Link to={`/producten/${id}`}>
            <h3 className="title is-5">{name}</h3>
          </Link>
          <p>
            {format(price, tva)} {price >0 && <span className="is-size-7">incl. BTW</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

const ExerciseTile: FunctionComponent<Exercise & RouteComponentProps> = ({ id, name, imageUrls, description }) => {
  return (
    <div className="column exercise">
      <div className="card with-shadow has-hover">
        <div className="card-image has-text-centered">
          <Link to={`/oefeningen/${id}`} className="img-has-overlay">
            <figure className="image is-4by3">
              <img src={imageUrls[0]} alt={name} />
            </figure>
          </Link>
        </div>
        <div className="card-footer has-text-centered">
          <Link to={`/oefeningen/${id}`}>
            <h3 className="title is-5">{name}</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

const NewsArticleTile: FunctionComponent<Article & RouteComponentProps> = ({ id, title, image, text }) => {
  return (
    <div className="column new-article">
      <div className="card with-shadow has-hover">
        <div className="card-image has-text-centered">
          <Link to={`/nieuws/${id}`} className="img-has-overlay">
            <figure className="image is-4by3">
              <img src={image} alt={title} />
            </figure>
            <span className="overlay">
              <div className="content">
                <p />
              </div>
            </span>
          </Link>
        </div>
        <div className="card-footer has-text-centered">
          <Link to={`/nieuws/${id}`}>
            <h3 className="title is-5">{title}</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

type Props = {
  data: Data;
};

type State = {
  products: Product[];
  exercises: Exercise[];
  clients: Client[];
  articles: Article[];
  rent_index: string;
};

class Home extends Component<Props & RouteComponentProps, State> {
  state: State = {
    products: [],
    exercises: [],
    clients: [],
    articles: [],
    rent_index: "",
  };

  async componentDidMount() {
    const data = await Promise.all([
      await this.props.data.getProducts(),
      await this.props.data.getExercises(),
      await this.props.data.getClients(),
      await this.props.data.getArticles(),
    ]);
    const [products, exercises, clients, articles] = data;
    this.setState({ products, exercises, clients, articles });
  }

  render() {
    const { products, exercises, clients, articles, rent_index } = this.state;

    const rentables = products.filter(
      product => product.rentable
    );
    if (rent_index === "" && rentables.length > 0) {
      this.setState({
        rent_index: rentables[0].id,
      });
    }
    return (
      <div className="home">
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              <div className="clients">
                <div className="level is-mobile">
                  {clients.map(client => (
                    <div key={client.name} className="level-item has-text-centered">
                      <div>
                        <img src={client.logo} alt={client.name} />
                        <p className="heading">{client.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="contact">
                <a
                  href="/contact#interesse"
                  className="button is-primary is-fullwidth"
                  style={{ marginBottom: "10px" }}
                >
                  Interesse?
                </a>
                <div className="content has-text-centered">
                  <p>Stel vrijblijvend een vraag</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="columns">
            <div className="column is-9">
              <div className="products">
                <h2 className="title is-3 has-text-centered">Producten</h2>
                <div className="columns">
                  {products
                    .sort((a, b) => b.priority - a.priority)
                    .slice(0, 3)
                    .map(product => (
                      <ProductTile key={product.id} {...product} />
                    ))}
                </div>
                <div className="has-text-centered">
                  <Link to="/producten" className="button is-primary with-shadow has-hover">
                    Bekijk alle producten
                  </Link>
                </div>
              </div>
              <hr />
              <div className="exercises has-text-centered">
                <h2 className="title is-3">Oefeningen</h2>
                <div className="columns">
                  {exercises.sort((a, b) => b.priority - a.priority).sort(function(a,b){
                    return new Date(b.date).getTime() - new Date(a.date).getTime();
                  }).slice(0, 5).map(exercise => (
                    <ExerciseTile key={exercise.id} {...exercise} />
                  ))}
                </div>
                <Link to="/oefeningen" className="button is-primary with-shadow has-hover">
                  Bekijk alle oefeningen
                </Link>
              </div>
              <hr />
              <div className="articles has-text-centered">
                <h2 className="title is-3">Get-Outt Nieuws</h2>
                <div className="columns">
                  {articles.map(article => (
                    <NewsArticleTile key={article.id} {...article} />
                  ))}
                </div>
                <Link to="/nieuws" className="button is-primary with-shadow has-hover">
                  Bekijk alle artikelen
                </Link>
              </div>
              <hr />
              <div className="partner has-text-centered">
                <h2 className="title is-3">Partner in de kijker: GorillaGrip</h2>
                <div>
                  <img src="https://www.gorillagrip.nl/media/7720/gorilla-grip-logo.png" />
                  <h3 className="title is-4">We don't fear competition!</h3>
                  <p>
                    Degelijke onverwoestbare riggs, attatchments en equipment om je box mee te realiseren! Kwaliteit,
                    Duurzaam en Functioneel zijn de kernwoorden voor alle GorillaGrip producten. Het nieuwe
                    vooruitstrevende sterke merk van Europa!
                  </p>
                </div>
                <a
                  href="https://www.gorillagrip.nl/nl/home"
                  target="_blank"
                  className="button is-light with-shadow has-hover"
                >
                  Bezoek GorillaGrip
                </a>
              </div>
            </div>
            <div className="column is-3">
              <div className="calendar">
                <h2 className="title is-4">Huur onze boxen!</h2>
                <div className="control">
                  <div className="select">
                    <select value={rent_index} onChange={e => this.setState({ rent_index: e.target.value })}>
                      {rentables.map(rentable => {
                        return (
                          <option key={rentable.id} value={rentable.id}>
                            {rentable.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <Link to={"/huur/" + rent_index} className="button is-dark has-hover">
                  Bekijk Beschikbaarheden
                </Link>
              </div>
              <div className="socials">
                <h2 className="title is-4">Sociale Media Nieuws</h2>
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/Get-Outt-Het-Zwitsers-zakmes-van-de-fitness-1010308349107938&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=286314972062510"
                  width="340"
                  height="500"
                  style={{"border":"none","overflow":"hidden", maxWidth: "100%", minHeight: "700px"}} scrolling="no" frameBorder="0" allow="encrypted-media"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
