//const API_URI = "http://localhost:1337"; // local
//const API_URI = "https://api.staging.get-outt.be"; // staging
const API_URI = "https://get-outt-api.azurewebsites.net"; // prod

export type RentPrice = {
  label: string;
  price: number;
  code?: string;
};

export type General = {
  mission: string;
  vision: string;
  values: string;
  address: string;
  iban: string;
  btw: string;
};

export type Client = {
  name: string;
  logo: string;
};

export type MuscleGroup = {
  id: string;
  name: string;
};

export type Service = {
  id: string;
  label: string;
  price: number;
  code?: string;
  multiple: boolean;
  max: number;
  amount?: number;
};

export type Category = {
  name?: string;
  category: string;
  priority: number;
  products: Product[];
  show: boolean;
};

export type Employee = {
  id: string;
  name: string;
  role: string;
  image: string;
  avatar: string;
  description: string;
  email: string;
  linkedInUrl: string;
};

export type CartItem = {
  product: Product;
  amount: number;
};

export type Unavailability = {
  start: Date;
  end: Date;
};

export type Product = {
  id: string;
  name: string;
  imageUrls: string[];
  price: number;
  priority: number;
  description: string;
  parts: Product[];
  technicalspecs: string;
  contract: string;
  rentprices: RentPrice[];
  delivery: string;
  services: Service[];
  categories: Category[];
  rentable: boolean;
  buyable: boolean;
  unavailabilities: Unavailability[];
  tva: number;
};

export type Exercise = {
  id: string;
  name: string;
  description: string;
  imageUrls: string;
  priority: number;
  products: Product[];
  youtube_code: string;
  visible: boolean;
  musclegroups: MuscleGroup[];
  date: Date;
};

export type Article = {
  id: string;
  title: string;
  image: string;
  text: string;
  date: string;
  link: string;
};

export class Data {
  async getTeam(): Promise<Employee[]> {
    var response = await fetch(API_URI + "/employees").then(response => response.json());
    response = response.map((emp: any) => {
      return {
        ...emp,
        image: emp.image.url,
        avatar: emp.avatar.url,
      };
    });
    return await response;
  }

  async getCategories(): Promise<Category[]> {
    var response = await fetch(API_URI + "/categories").then(response => response.json());
    return await response;
  }

  async getProducts(): Promise<Product[]> {
    var response = await fetch(API_URI + "/products").then(response => response.json());
    response = response.map((box: any) => {
      return {
        ...box,
        imageUrls: box.image.map((im: any) => im.url),
      };
    });
    return await response;
  }

  async getExercises(): Promise<Exercise[]> {
    var response = await fetch(API_URI + "/exercises").then(response => response.json());
    response = response.filter((exercise: any) => exercise.visible).map((exercise: any) => {
      return {
        ...exercise,
        imageUrls: exercise.image.map((im: any) => im.url),
      };
    });
    return await response;
  }

  async getClients(): Promise<Client[]> {
    var response = await fetch(API_URI + "/clients").then(response => response.json());
    response = response.map((client: any) => {
      return {
        ...client,
        logo: client.logo.url,
      };
    });
    return await response;
  }

  async getGeneral(): Promise<General> {
    var response = await fetch(API_URI + "/generals").then(response => response.json());
    response = response.map((general: any) => {
      return {
        ...general,
      };
    });
    return await response[0];
  }

  async getArticles(): Promise<Article[]> {
    var response = await fetch(API_URI + "/articles").then(response => response.json());
    response = response.map((article: any) => {
      return {
        ...article,
        image: article.image.url,
      };
    });
    return await response;
  }

  async getServices(): Promise<Service[]> {
    var response = await fetch(API_URI + "/services").then(response => response.json());
    response = response.map((service: any) => {
      return {
        ...service,
      };
    });
    return await response;
  }
}
