import * as React from "react";
import "./TeamList.scss";
import { FunctionComponent } from "react";
import { Link, Match } from "@reach/router";
import { Employee } from "../Data";
import Team from "./Team";

type MenuLinkProps = {
  to: string;
};

const MenuLink: FunctionComponent<MenuLinkProps> = props => {
  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        return {
          className: isCurrent ? "is-active" : "",
        };
      }}
    />
  );
};

type Props = {
  team: Employee[];
  employeeId?: string;
};

const Sidebar: FunctionComponent<Props> = ({ team }) => {
  return (
    <aside className="menu">
      <strong className="menu-label">wie zijn wij</strong>
      <ul className="menu-list">
        <li>
          <Match path="/over-ons/team/">
            {({ match }) => (
              <Link to="/over-ons/team/" getProps={() => ({ className: match ? "is-active" : "" })}>
                Team
              </Link>
            )}
          </Match>
          <Match path="/over-ons/team/">
            {({ match }) => (
              <ul className={match ? "" : "is-hidden"}>
                {team.map(({ id, name }) => (
                  <li key={id}>
                    <Match path="/over-ons/team/">
                      {({ match }) => (
                        <Link
                          to={`/over-ons/team/?id=${id}`}
                          getProps={() => ({ className: match && Team.getEmployeeId() === id ? "is-active" : "" })}
                        >
                          {name}
                        </Link>
                      )}
                    </Match>
                  </li>
                ))}
              </ul>
            )}
          </Match>
        </li>
        <li>
          <MenuLink to="/over-ons/missie">
            Missie
            <br />
            Visie
            <br />
            Kernwaarden
          </MenuLink>
        </li>
        {false && (
          <li>
            <a>Transpa-log</a>
          </li>
        )}
        <li>
          <MenuLink to="/contact">Contacteer Ons</MenuLink>
        </li>
        {false && (
          <li>
            <a>Bezoek ons</a>
          </li>
        )}
      </ul>
      <strong className="menu-label">Feedback</strong>
      <ul className="menu-list">
        <li>
          <MenuLink to="/contact#vraag">Vraag</MenuLink>
        </li>
        <li>
          <MenuLink to="/contact#klacht">Klacht</MenuLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
