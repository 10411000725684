import * as React from "react";
import "./Products.scss";
import { Component } from "react";
import { connect } from "react-redux";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import { Data, Product, Service, Category } from "./Data";
import { format } from "./currency";
import Page from "./Page";
import SlideShow from "./SlideShow";
import { addToCart } from "./Reducers/CartReducer";
import { toggleCart } from "./Reducers/GeneralReducer";
import Markdown from "./Markdown";

type Props = {
  data: Data;
  productId?: string;
  addToCart: (product: Product) => void;
  toggleCart: () => void;
};

type State = {
  products: Product[];
  services: Service[];
  categories: Category[];
};

class Products extends Component<Props & RouteComponentProps, State> {
  state: State = {
    products: [],
    services: [],
    categories: [],
  };

  async componentDidMount() {
    const data = await Promise.all([await this.props.data.getProducts(), await this.props.data.getServices(), await this.props.data.getCategories()]);
    const [products, services, categories] = data;
    this.setState({ products, services, categories });

    if (!this.props.productId) {
      const firstProduct = categories.sort((a, b) => b.priority - a.priority)[0].products[0];
      //const [firstProduct] = products;

      return navigate(`/producten/${firstProduct.id}`);
    }
  }

  componentWillReceiveProps(nextProps: Readonly<Props & RouteComponentProps>, nextContext: any) {
    if (!nextProps.productId) {
      const firstProduct = this.state.categories.sort((a, b) => b.priority - a.priority)[0].products[0];
      //const [firstProduct] = this.state.products;

      return navigate(`/producten/${firstProduct.id}`);
    }
  }

  render() {
    const { products, services, categories } = this.state;
    const { productId, addToCart, toggleCart } = this.props;
    let productInfo;

    if (productId) {
      const product = products.find(product => product.id === productId);
      if (product) {
        const { id, name, imageUrls, description, parts, price, technicalspecs, contract, rentable, buyable, tva } = product;
        productInfo = (
          <div key={id} className="box with-shadow product">
            <div className="level product-title">
              <div className="level-right">
                <h2 className="title is-3">{name}</h2>
              </div>
              <div className="level-left">
                <p className="is-size-4 level-item">
                  {format(price, tva)}{" "}
                  {price >0 && <span className="is-size-6" style={{ marginLeft: "5px" }}>
                    incl. BTW
                  </span> }
                </p>
                {buyable && (<button
                  className="button is-primary level-item"
                  onClick={() => {
                    addToCart(product);
                    toggleCart();
                  }}
                >
                  {product.price > 999 ? "Offerte aanvragen" : "Kopen"}
                </button>
                )}
                {rentable && (
                  <Link to={"/huur/" + id} className="button is-light level-item">
                    Huren
                  </Link>
                )}
              </div>
            </div>
            <div className="columns">
              <div className="column is-6">
                <SlideShow imageUrls={imageUrls} />
                <h3 className="title is-4" style={{ marginTop: "20px" }}>
                  Beschrijving
                </h3>
                <div className="content">
                  <Markdown>{description}</Markdown>
                </div>
              </div>
              <div className="column is-6">
                {parts.length > 0 && <h3 className="title is-4">Onderdelen in de box</h3>}
                {parts.length > 0 && (
                  <div className="content">
                    <ul>
                      {parts.map((part, index) => (
                        <li key={index}>
                          <Link to={`/producten/${part.id}`}>{part.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <h3 className="title is-4">Technische specificaties</h3>
                <div className="content">
                  <Markdown>{technicalspecs}</Markdown>
                </div>
                <h3 className="title is-4">Onderhoudscontract</h3>
                <div className="content">
                  <Markdown>{contract}</Markdown>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    const categoryGroups = new Map();
    products.forEach(product => {
      product.categories.map((category: any) => {
        const collection = categoryGroups.get(category.category);
        if (!collection) {
          categoryGroups.set(category.category, [product]);
        } else {
          collection.push(product);
        }
      });
    });
    const keys = Array.from(categoryGroups.keys());

    return (
      <Page title="Producten">
        <div className="columns products">
          <div className="column is-2-tablet is-2-desktop">
            <aside className="menu">
              {categories.sort((a, b) => b.priority - a.priority).map((category, key) => {
                return (
                  <div key={key}>
                    <strong className="menu-label">{category.category}</strong>
                    {!category.show && <span style={{ display: "block", cursor: "pointer", fontSize: "0.75em", padding: "0px 0px 10px 0px" }} onClick={()=>{
                        categories.map((cat => {
                          if(cat === category){
                            cat.show = true;
                          }
                        }));
                        this.setState({
                          categories,
                        })
                      }}>Toon meer</span>}
                    <ul className="menu-list">
                      {category.show && category.products.map((product: Product) => {
                        return (
                          <li key={product.id}>
                            <Link
                              to={`/producten/${product.id}`}
                              className={productId === product.id ? "is-active" : ""}
                            >
                              {product.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    {category.show && <span style={{ display: "block", cursor: "pointer", fontSize: "0.75em", padding: "0px 0px 10px 0px", marginTop: "10px" }} onClick={()=>{
                        categories.map((cat => {
                          if(cat === category){
                            cat.show = false;
                          }
                        }));
                        this.setState({
                          categories,
                        })
                      }}>Toon minder</span>}
                  </div>
                );
              })}
              {false && (
                <div>
                  <p className="menu-label">Diensten</p>
                  <ul className="menu-list">
                    {services.map((service: Service) => {
                      return (
                        <li key={service.id}>
                          <Link to={`/producten/${service.id}`} className={productId === service.id ? "is-active" : ""}>
                            {service.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <div>
                <strong className="menu-label">Diverse</strong>
                <ul className="menu-list">
                  <li>
                    <Link to="/contact">Project op maat</Link>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
          <div className="column">{productInfo}</div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  addToCart: (product: Product) => dispatch(addToCart(product)),
  toggleCart: () => dispatch(toggleCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);
