import * as React from "react";
import "./TeamList.scss";
import { Component } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import Sidebar from "./Sidebar";
import { Data, Employee, General } from "../Data";
import TeamList from "./TeamList";
import Page from "../Page";
import Markdown from "../Markdown";

type Props = {
  data: Data;
};

type State = {
  team: Employee[];
  general: General;
};

export default class Mission extends Component<Props & RouteComponentProps, State> {
  state: State = {
    team: [],
    general: {
      mission: "",
      vision: "",
      values: "",
      address: "",
      iban: "",
      btw: "",
    },
  };

  async componentDidMount() {
    const data = await Promise.all([await this.props.data.getTeam(), await this.props.data.getGeneral()]);
    const [team, general] = data;
    this.setState({ team, general });
  }

  render() {
    const { team, general } = this.state;

    return (
      <Page title={<>Missie, Visie & Kernwaarden</>}>
        <div className="columns">
          <div className="column is-3-tablet is-2-desktop">
            <Sidebar team={team} />
          </div>
          <div className="column">
            <div className="columns">
              <div className="column is-8">
                <div>
                  <h3 className="title is-3">Missie</h3>
                  <Markdown>{general.mission}</Markdown>
                </div>
                <hr />
                <div>
                  <h3 className="title is-3">Visie</h3>
                  <Markdown>{general.vision}</Markdown>
                </div>
                <hr />
                <div>
                  <h3 className="title is-3">Kernwaarden</h3>
                  <Markdown>{general.values}</Markdown>
                </div>
              </div>
              <div className="column is-4">
                <div>
                  <h3 className="title is-3">Bedrijfsgegevens</h3>
                  <div>
                    <Markdown>{general.address}</Markdown>
                  </div>
                </div>
                <hr />
                <div>
                  <TeamList team={team} />
                </div>
                <hr />
                <div className="has-text-centered">
                  <Link className="button is-primary" to="/contact">
                    Laat een bericht na
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
