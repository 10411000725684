import * as React from "react";
import { Component } from "react";
import { RouteComponentProps, globalHistory } from "@reach/router";
import Sidebar from "./Sidebar";
import { Data, Employee } from "../Data";
import TeamList from "./TeamList";
import Page from "../Page";

import "./Team.scss";

type Props = {
  data: Data;
};

type State = {
  team: Employee[];
  employeeId: string;
};

export default class Team extends Component<Props & RouteComponentProps, State> {
  state: State = {
    team: [],
    employeeId: Team.getEmployeeId(),
  };

  unsubscribe?: () => void;

  static getEmployeeId(): string {
    const url = new URL(window.location.href);

    return url.searchParams.get("id") || "";
  }

  handleRouteChange() {
    this.setState({ employeeId: Team.getEmployeeId() });
  }

  async componentDidMount() {
    this.unsubscribe = globalHistory.listen(this.handleRouteChange.bind(this));
    const team = await this.props.data.getTeam();
    this.setState({ team: team });
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  render() {
    let { team, employeeId } = this.state;
    let info = null;

    if (!employeeId) {
      const [employee] = team;
      if (employee) {
        employeeId = employee.id;
      }
    }

    if (employeeId) {
      const employee = team.find(employee => employee.id === employeeId);
      if (employee) {
        const { image, name, role, description, email, linkedInUrl } = employee;
        info = (
          <div className="team">
            <p className="title is-3" style={{ marginTop: "20px" }}>
              {name}
            </p>
            <p className="subtitle is-4">{role}</p>
            <div className="content" style={{ color: "#5a6769" }}>
              {description}
            </div>
            { email && <p>
              <b>Contacteer mij via mail: </b>
              <a href={`mailto:${email}`}>{email}</a>
            </p> }
            {linkedInUrl && <p>
              <b>Link met mij via </b>
              <a href={linkedInUrl} target="_blank">LinkedIn</a>
            </p>}
          </div>
        );
      }
    }

    var image;
    if (employeeId) {
      var temp = team.find(employee => employee.id === employeeId);
      if (temp) {
        image = temp.image;
      }
    }
    return (
      <Page title="Team">
        <div className="columns">
          <div className="column is-3-tablet is-2-desktop">
            <Sidebar team={team} employeeId={employeeId} />
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">{info}</div>
              <div className="column">
                {employeeId && <figure className="image" style={{ margin: "40px auto", maxWidth: "300px" }}>
                  <img src={image} alt={name} />
                </figure>}
                <TeamList team={team} employeeId={this.state.employeeId} />
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
