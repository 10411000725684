import { CartItem, Product, Service } from "./Data";

//const STRAPI_URI = "http://localhost:1337";
//const STRAPI_URI = "https://api.staging.get-outt.be";
const STRAPI_URI = "https://get-outt-api.azurewebsites.net";

function getStrapiUri(endpoint: string) {
  return `${STRAPI_URI}${endpoint}`;
}

export const getHeaders = async () => {
  return {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
  };
};

const POST = async (endpoint: string, body: object) => {
  return fetch(endpoint, {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body),
  }).then(response => response.json());
};

export function finishCheckout(
  email: string,
  firstname: string,
  lastname: string,
  items: CartItem[],
  btw: string,
  remarks: string,
  choice: string,
  address: string,
  mobile: string,
) {
  fetch("https://hooks.zapier.com/hooks/catch/4001602/ood2ep7/", {
    method: "POST",
    body: JSON.stringify({
      "firstname": firstname,
      "email": email,
    }),
  });
  POST(getStrapiUri("/teamleader/checkout"), {
    email,
    firstname,
    lastname,
    items,
    btw,
    remarks,
    choice,
    address,
    mobile,
  });
}

export function finishRent(
  email: string,
  firstname: string,
  lastname: string,
  product: Product,
  services: Service[],
  start: string,
  end: string,
  coupon: string,
  delivery: string,
  postal: string
) {
  fetch("https://hooks.zapier.com/hooks/catch/4001602/ood2ep7/", {
    method: "POST",
    body: JSON.stringify({
      "firstname": firstname,
      "email": email,
    }),
  });
  POST(getStrapiUri("/teamleader/rent"), {
    email,
    firstname,
    lastname,
    product: product.name,
    services,
    start,
    end,
    coupon,
    delivery,
    postal,
  });
}

export function contact(email: string, firstname: string, lastname: string, type: string, message: string) {
  fetch("https://hooks.zapier.com/hooks/catch/4001602/ood2ep7/", {
    method: "POST",
    body: JSON.stringify({
      "firstname": firstname,
      "email": email,
    }),
  });
  const response = POST(getStrapiUri("/teamleader/contact"), {
    email,
    firstname,
    lastname,
    type,
    message,
  }).then(response => {
    debugger;
  });
}
